.preview {
  width: calc((100% / 5) - 30px);
  height: calc((100% / 3) - 30px);
  min-width: 250px;
  min-height: 200px;
  max-width: 500px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 0;
  // background: var(--color-accent);
  background: white;
  -webkit-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  margin: 30px;
  transition: all .5s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  .image-lay {
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    pointer-events: none;

    .video {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      // image-rendering: pixelated;
      object-position: top;
      object-fit: cover;
      pointer-events: none;
    }

    // .canvas {
    //   width: 100%;
    //   height: 100%;
    //   display: none;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    //   position: absolute;
    //   border-top-left-radius: 16px;
    //   border-top-right-radius: 16px;
    //   object-position: top;
    //   object-fit: cover;
    //   pointer-events: none;
    // }

    .image {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      // image-rendering: pixelated;
      object-position: top;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .info-lay {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    pointer-events: none;
    padding: 4px 8px;

    .name-text {
      // width: 100%;
      display: -webkit-box;
      position: relative;
      padding: 2px 4px;
      font-size: 1.2rem;
      font-weight: bold;
      color: black;
      text-align: center;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      pointer-events: none;
      word-break: break-all;
    }
  }

  .plus-icon {
    width: 60%;
    height: 60%;
    color: black;
  }

  .close-btn {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    background: white;
    border-radius: 50%;
    padding: 4px;
  }

  .center-view {
    width: 10px;
    height: 10px;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}

.also-plus-btn {
  justify-content: center;
}