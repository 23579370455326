.app-main {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  background: #B40003;
  overflow: hidden;

  .bg-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    image-rendering: pixelated;
    object-position: center;
    object-fit: cover;
  }

  .list-lay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px 24px;
  }

  .player-lay {
    width: 100vw;
    height: 100vh;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    z-index: 999;
    // background: black;
    background: #B40003;
    border-radius: 0px;
    opacity: 0;
    transform: scale(0);
    transition: transform 0.5s, width 0.5s, height 0.5s;
  }

  .btns-lay {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 2;

    .btns-btn {
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: transparent;
      color: white;
      cursor: pointer;
      margin: 12px 0;
    }
  }
}