.player {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: inherit;
  border-radius: inherit;

  .top-view {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    z-index: 9999;
    cursor: pointer;
    border-radius: inherit;
  }

  .video-lay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain;
    object-position: center;
    // image-rendering: pixelated;
    background: inherit;
    border-radius: inherit;

    video {
      background: inherit;
    }
  }

  .image-lay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain;
    object-position: center;
    background: inherit;
    border-radius: inherit;

    .image {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      object-fit: contain;
      object-position: center;
      // image-rendering: pixelated;
      border-radius: inherit;
    }
  }
}